<template>
  <div v-if="user && user.name">
    <div
      class="elem"
      :class="{ 'elem--sub': isSubItem }"
    >
      <div class="fio">
        <div
          class="inner"
          style="cursor: pointer"
          @click="isSubItem = !isSubItem"
        >
          <div class="arrow">
            <v-icon
              class="mr-2 chevron"
              :style="{transform: isSubItem ? 'rotate(180deg)' : 'rotate(0deg)'}"
            >mdi-chevron-down</v-icon>
          </div>
          <div class="description">
            <div
              class="name"
              :style="{'font-weight': isSubItem ? '600' : '500'}"
            >{{user.name}}</div>

            <div
              v-if="user.is_outsource"
              class="outsourcing"
            >Аутстаф</div>
          </div>
          <v-menu
            class="dots"
            key="text"
            rounded="lg"
            offset-y
          >
<!--            <template v-slot:activator="{ attrs, on }">-->
<!--              <v-btn-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--                icon-->
<!--                v-if="user.id !== 'Abstract'"-->
<!--              >-->
<!--                <v-icon>mdi-dots-vertical</v-icon>-->
<!--              </v-btn>-->

<!--              <v-btn-->
<!--                style="opacity: 0"-->
<!--                icon-->
<!--                v-else-->
<!--              ></v-btn>-->
<!--            </template>-->

<!--            <v-list>-->
<!--              <v-list-item-->
<!--                link-->
<!--                @click="openAddProject()"-->
<!--                dense-->
<!--              >-->
<!--                <v-list-item-title>Добавить проект</v-list-item-title>-->
<!--              </v-list-item>-->
<!--            </v-list>-->
          </v-menu>

          <AddProjectModalTwoCalendars
            :isAddProjectModal="isAddProjectModal"
            @addProjectModal="addProjectModal"
            @addProject="addProject"
          />

          <AddAppointEmployees
            :isAddProjectModal="isOpenAddAppointEmployees"
            @addProjectModal="addAppointEmployees"
            @addProject="addProjectForAbstract"
          />
        </div>
      </div>

      <div
        class="position"
        :style="{'font-weight': isSubItem ? '600' : '500'}"
      >{{user.position}}</div>

      <template v-if="user.id !== 'Abstract'">

        <div
          v-for="(item,index) in user.base_values"
          :key="index"
          class="item"
          :style="{
            'font-weight': isSubItem ? '600' : '500',
            background: item.data.plan < getNumberForTableCell(item) ?
            `linear-gradient(
              to top, #D8E7FE ${100/(getNumberForTableCell(item)/item.data.plan)}%,
               ${isSubItem ? 'rgb(254, 250, 247)' : 'white'} ${100/(getNumberForTableCell(item)/item.data.plan)}%)`
              : item.data.plan > getNumberForTableCell(item) ? `#FEDFD8` : '#E6FED8'
          }"
        >
          <div v-if="item.data.type === 'work'">
            {{planTab !== 0 ? `${item.data.plan} / ${item.data.fact}` : `${item.data.plan}`}}
          </div>
          <div v-else-if="item.data.type === 'vacation'">🌴</div>
          <div v-else-if="item.data.type === 'dayoff'">🕺</div>
          <div v-else-if="item.data.type === 'sick'">🤕</div>
        </div>
      </template>

      <template v-else>
        <div
          v-for="(item,index) in user.base_values"
          :key="index"
          class="item"
        >
        </div>
      </template>

      <div
        class="total"
        :style="{
          'font-weight': isSubItem ? '600' : '500',
        }"
      >

        <span v-if="user.id !== 'Abstract'">
          {{planTab !== 0 ? `${user.total.plan} / ${user.total.fact}` : `${user.total.plan}`}}
        </span>

      </div>
      <div
        v-if="planTab !== 0"
        class="deviation"
        :style="{'font-weight': isSubItem ? '600' : '500'}"
      >
        <span v-if="user.id !== 'Abstract'">
          {{user.deviation}}%
        </span>
      </div>
    </div>

    <div v-if="isSubItem">
      <div
        class="elem elemProject"
        :class="{ 'elem--sub': isSubItem }"
        v-for="(project,indexProjects) in user.projects"
        :key="indexProjects"
      >
        <div class="fio">
          <div>{{project.name}}</div>
          <v-menu>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                v-if="user.id === 'Abstract'"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
              <v-tooltip v-else top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteEmployee({project_id: project.id, user_id: user.id, position: user.position})"
                  >
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Удалить сотрудника с проекта</span>
              </v-tooltip>
            </template>

            <v-list>
              <v-list-item
                link
                @click="openOrderSpecialist({project: project.id, position: user.position})"
                dense
              >
                <v-list-item-title>Заявка на специалиста</v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="openAppointEmployees({project: project.id, position: user.position})"
                dense
              >
                <v-list-item-title>Назначить сотрудника</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="position" v-if="user.id === 'Abstract'">
          {{project.position}}
          <br>
          ({{project.value}})
        </div>

        <div class="position" v-if="user.id !== 'Abstract'">{{project.position}}</div>

        <template v-if="user.id !== 'Abstract'">

          <div
            v-for="(itemProject,indexProject) in project.cells"
            :key="indexProject"
            class="item itemProject"
            :class="{'itemProjectChecked': isItemProjectChecked(project, itemProject)}"
            @click="checkedItemProject(project, itemProject)"
            @mousemove="checkedItemProjectMove($event, project, itemProject)"
          >
            <div v-if="itemProject.data.type === 'work'">
              {{planTab !== 0 ? `${itemProject.data.plan} / ${itemProject.data.fact}` : `${itemProject.data.plan}`}}
            </div>
            <div v-else-if="itemProject.data.type === 'vacation'">🌴</div>
            <div v-else-if="itemProject.data.type === 'dayoff'">🕺</div>
            <div v-else-if="itemProject.data.type === 'sick'">🤕</div>
          </div>

        </template>

        <template v-else>
          <div
            v-for="(itemProject,indexProject) in project.cells"
            :key="indexProject"
            class="item"
          >

          </div>
        </template>

        <div class="total">
          <span v-if="user.id !== 'Abstract'">
            {{planTab !== 0 ? `${project.total.plan} / ${project.total.fact}` : `${project.total.plan}`}}
          </span>
        </div>

        <div
          v-if="planTab !== 0"
          class="deviation"
        >
          <span v-if="user.id !== 'Abstract'">
            {{project.deviation}}%
          </span>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import AddProjectModalTwoCalendars from '@/components/UI/AddProjectModalTwoCalendars.vue';
import AddAppointEmployees from '@/components/UI/addAppointEmployees.vue';

export default {
  name: 'Table',
  components: {
    AddProjectModalTwoCalendars,
    AddAppointEmployees,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    usersProjectItemsChecked: {
      type: Array,
      default: () => [],
    },
    planTab: {
      type: Number,
      default: 0,
    },
    mouseIsPress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      x: 0,
      mouseLeft: false,
      isSubItem: false,
      isAddProjectModal: false,
      isOpenAddAppointEmployees: false,
    };
  },
  methods: {
    deleteEmployee(data) {
      this.$emitter.$emit('deleteEmployeeFromProject', data);
    },
    getNumberForTableCell(val) {
      if (val.type === 'day') return 8;
      if (val.type === 'month') return 40;
      return 880;
    },
    isItemProjectChecked(project, itemProject) {
      if (this.usersProjectItemsChecked.find((el) => el.id === this.user.id)) {
        const user = this.usersProjectItemsChecked.find((el) => el.id === this.user.id);
        if (user.projects.find((el) => el.id === project.id)) {
          const proj = user.projects.find((el) => el.id === project.id);
          if (proj.cells.find((el) => el.date === itemProject.date)) {
            return true;
          }
        }
      }
      return false;
    },
    checkedItemProject(project, itemProject) {
      this.$emit('usersProjectItemsCheckedChange', {
        id: this.user.id,
        position: this.user.position,
        projectId: project.id,
        date: itemProject.date,
      });
    },
    checkedItemProjectMove(e, project, itemProject) {
      if (this.mouseIsPress) {
        if (e.x > this.x) {
          if (!this.isItemProjectChecked(project, itemProject)) {
            this.$emit('usersProjectItemsCheckedChange', {
              id: this.user.id,
              position: this.user.position,
              projectId: project.id,
              date: itemProject.date,
            });
          }
        } else if (e.x < this.x && this.isItemProjectChecked(project, itemProject)) {
          this.$emit('usersProjectItemsCheckedChange', {
            id: this.user.id,
            position: this.user.position,
            projectId: project.id,
            date: itemProject.date,
          });
        }
      }
      this.x = e.x;
    },
    addProject(val) {
      this.$emit('addProjectForUser', {
        ...val,
        userId: this.user.id,
      });
      this.isAddProjectModal = false;
    },

    openAddProject() {
      this.addProjectModal(true);
    },
    openOrderSpecialist(user) {
      this.$emit('openOrderSpecialist', user);
    },
    addProjectModal(bool) {
      this.isAddProjectModal = bool;
    },
    openAppointEmployees() {
      this.addAppointEmployees(true);
    },
    addAppointEmployees(bool) {
      this.isOpenAddAppointEmployees = bool;
    },
    addProjectForAbstract(val) {
      this.$emit('addProjectForAbstract', val);
      this.addAppointEmployees(false);
    },
  },
};
</script>

<style scoped lang="scss">
* {
  font-weight: 500;
  font-size: 14px;
  font-family: sans-serif;
  user-select: none;
}
.elem {
  display: flex;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
  }
  .fio {
    flex-direction: column;
    align-items: start;
    width: 300px;
    min-width: 300px;
    border-left: 1px solid #d6d6d6;
    .inner {
      display: flex;
      justify-content: space-around;
      width: 100%;
      .arrow {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 70px);
        min-width: calc(100% - 70px);
      }
      .dots {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }
    .outsourcing {
      font-size: 12px;
      color: #aaaaaa;
    }
  }

  &--sub {
    .item,
    .position,
    .fio,
    .total {
      background-color: #FEFAF7;
    }
  }

  .position {
    justify-content: center;
    padding: 0 10px;
    width: 120px;
    min-width: 120px;
    text-align: center;
  }

  .item {
    height: 50px;
    width: 75px;
    min-width: 75px;
  }
  .total {
    width: 75px;
    min-width: 75px;
  }
  .deviation {
    width: 75px;
    min-width: 75px;
  }
}

.elemProject {
  user-select: none;
  .itemProject {
    cursor: pointer;
    &:hover {
      background: #dadada;
      opacity: 0.8;
    }
  }
  .itemProjectChecked {
    background: #bdcbf0 !important;
  }
  .fio {
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      position: relative;
      left: 4px;
    }
  }
  .fil-sub {
    background-color: #FEFAF7;
  }
}
</style>
