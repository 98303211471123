<template>
  <div class="filters">
    <div class="left">

      <div class="date mt-4">
        <v-select
          v-if="tableParams.type === 'month'"
          :items="months"
          class="mr-4 select rounded-lg"
          outlined
          hide-details
          v-model="getMonth"
          rounded
        ></v-select>

        <v-select
          v-if="tableParams.type === 'quarter'"
          :items="quarter"
          class="mr-4 select rounded-lg"
          outlined
          hide-details
          v-model="getQuarter"
          rounded
        ></v-select>

        <v-select
          v-if="tableParams.type !== 'day'"
          :items="years"
          class="select rounded-lg"
          outlined
          hide-details
          v-model="getYear"
          rounded
        ></v-select>

        <DatePickerRange
          class="mt-4"
          v-if="tableParams.type === 'day'"
          :defaultDates="tableParams.day"
          @changeCurrentDates="changeCurrentDates"
          :blockedDays="true"
        />
      </div>

      <div class="period">
        <v-select
          :items="periods"
          class="select rounded-lg mt-4"
          outlined
          hide-details
          v-model="getType"
          rounded
        ></v-select>
      </div>
    </div>

    <div class="right">
      <PlanFilter
        class="mt-4 mr-6"
        @changeFilters="changeFilters"
      />
      <v-btn
        outlined
        x-large
        @click="isSetClockDate = true"
        rounded
        class="rounded-lg mr-4 mt-4"
        :disabled="!isShowSetClock"
      >Установить загрузку</v-btn>

      <SetClockModal
        :isSetClockDate="isSetClockDate"
        @setClock="isSetClockDate = false"
        @setClockUsers="setClockUsers"
      />

      <v-btn
        outlined
        x-large
        @click="openAll"
        rounded
        class="rounded-lg mt-4"
      >
        {{isOpenAll ? 'Скрыть все' : 'Раскрыть все'}}</v-btn>
    </div>

  </div>
</template>

<script>
import DatePickerRange from '@/components/UI/DatePickerRange.vue';
import PlanFilter from './Filters/PlanFilter.vue';
import SetClockModal from './Filters/SetClockModal.vue';

export default {
  name: 'PlanMain',
  components: {
    DatePickerRange,
    PlanFilter,
    SetClockModal,
  },
  data() {
    return {
      isSetClockDate: false,
      isOpenAll: false,
      dates: [],
      periods: [
        { text: 'по дням', value: 'day' },
        { text: 'месяц', value: 'month' },
        { text: 'квартал', value: 'quarter' },
        { text: 'год', value: 'year' },
      ],
      months: [
        { text: 'Январь', value: '0' },
        { text: 'Февраль', value: '1' },
        { text: 'Март', value: '2' },
        { text: 'Апрель', value: '3' },
        { text: 'Май', value: '4' },
        { text: 'Июнь', value: '5' },
        { text: 'Июль', value: '6' },
        { text: 'Август', value: '7' },
        { text: 'Сентябрь', value: '8' },
        { text: 'Октябрь', value: '9' },
        { text: 'Ноябрь', value: '10' },
        { text: 'Декабрь', value: '11' },
      ],
      quarter: [
        { text: '1 квартал', value: '1' },
        { text: '2 квартал', value: '2' },
        { text: '3 квартал', value: '3' },
        { text: '4 квартал', value: '4' },
      ],
      years: [
        { text: '2021', value: '2021' },
        { text: '2022', value: '2022' },
        { text: '2023', value: '2023' },
        { text: '2024', value: '2024' },
      ],
    };
  },

  props: {
    isShowSetClock: {
      type: Boolean,
      default: false,
    },
    tableParams: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getMonth: {
      get() {
        return this.tableParams.month;
      },
      set(val) {
        this.tableParams.month = val;
        this.changeDate();
      },
    },
    getQuarter: {
      get() {
        return this.tableParams.quarter;
      },
      set(val) {
        this.tableParams.quarter = val;
        this.changeDate();
      },
    },
    getYear: {
      get() {
        return this.tableParams.year;
      },
      set(val) {
        this.tableParams.year = val;
        this.changeDate();
      },
    },
    getType: {
      get() {
        return this.tableParams.type;
      },
      set(val) {
        this.tableParams.type = val;
        this.changeDate();
      },
    },
  },

  methods: {
    changeDate() {
      this.$emit('changeDate', {
        day: this.dates,
      });
    },
    changeFilters(obj) {
      this.$emit('changeFilters', { ...obj });
    },
    changeCurrentDates(val) {
      this.dates = val;
      this.changeDate();
    },
    setClockUsers(obj) {
      this.$emit('setClockUsers', obj);
    },
    openAll() {
      this.isOpenAll = !this.isOpenAll;
      this.$emit('openAllListItems', this.isOpenAll);
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 11;
  background: #ffffff;
  padding: 16px 0;
  .left {
    display: flex;
    .date {
      display: flex;
      margin-right: 20px;
      width: 350px;
    }

    .period {
      margin-right: 20px;
      width: 200px;
    }
  }
  .right {
    display: flex;
    align-items: center;
  }
}
</style>
